let clearTimeoutHandle;
$('.__connection-state__container').hide();
document.addEventListener("DOMContentLoaded", () => {
    if (!navigator.onLine) {
        toggleContainer(false);
    }
});
window.addEventListener("online", () => {
    toggleContainer(true);
});
window.addEventListener("offline", () => {
    toggleContainer(false);
});
function toggleContainer(isOnline) {
    const connectionContainer = $('.__connection-state__container'), statusContainer = $('.__status'), imageContainer = $('.icon-logo');
    clearTimeout(clearTimeoutHandle);
    if (typeof isOnline !== undefined) {
        connectionContainer.fadeIn("slow");
        const is_Online = isOnline === true;
        const text = is_Online ? "Connection has been restored" : "Internet connection lost";
        const imgUrl = is_Online ? '/wifi.png' : '/no-internet.png';
        imageContainer.attr('src', imgUrl);
        statusContainer.text(text).css({ color: is_Online ? '#000' : 'red' });
        clearTimeoutHandle = setTimeout(() => {
            $(connectionContainer).fadeOut("slow");
        }, 5000);
    }
}
